import React, { useEffect, useRef, useState } from "react"
import MusicIdea1 from "../music/idea 1.wav"
import MusicIdea2 from "../music/idea 2.wav"
import MusicOreJul20 from "../music/ore jul 20.wav"
import PlayIcon from "../images/svg/music-player/play.svg"
import PauseIcon from "../images/svg/music-player/pause.svg"
import PreviousIcon from "../images/svg/music-player/previous.svg"
import ForwardIcon from "../images/svg/music-player/forward.svg"
import { useRecoilState } from "recoil"
import { playMusic } from "../state/music-player"

type Props = {
  playlistOverride?: {
    title: string,
    artist: string,
    src: string,
    autoplay: boolean,
  }[]
  loop?: boolean
}

const MusicPlayer: React.FC<Props> = ({ playlistOverride, loop }) => {
  const [play] = useRecoilState(playMusic)

  const [playlist, setPlaylist] = useState([
    {
      title: "song 0",
      artist: "artist 0",
      src: MusicIdea1,
      autoplay: false,
    },
    {
      title: "song 1",
      artist: "artist 1",
      src: MusicIdea2,
      autoplay: false,
    },
    {
      title: "song 2",
      artist: "artist 2",
      src: MusicOreJul20,
      autoplay: false,
    },
  ])

  useEffect(() => {
    if(playlistOverride){
      setPlaylist(playlistOverride)
    }
  }, [playlistOverride])

  const [index, setIndex] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const audio = useRef<HTMLAudioElement | null>(null)

  const handlePlay = () => {
    audio.current?.play()
  }

  const handlePause = () => {
    audio.current?.pause()
  }

  const handlePrev = () =>
    setIndex(index === 0 ? playlist.length - 1 : index - 1)

  const handleNext = () =>
    setIndex(index === playlist.length - 1 ? 0 : index + 1)

  const handleEnded = () => {
    handleNext()
    setIsPlaying(true)
  }

  useEffect(() => {
    if(playlist[index].autoplay && audio.current){
      const tryToPlay = setInterval(() => {
        audio.current?.play()
            .then(() => {clearInterval(tryToPlay)})
            .catch(e => e);
      }, 1000);
    }
  }, [index, audio.current, playlist])

  useEffect(() => {
    if(audio.current){
      play && audio.current?.play().catch(e => e);
      !play && audio.current?.pause();
    }
  }, [play])

  const classes = {
    btn: "h-[56px] w-[48px] flex items-center justify-center text-white bg-transparent",
    play: "h-[24px] w-[19px]",
    pause: "h-[23px] w-[23px]",
    nextPrev: "h-[16px] w-[16px]",
  }

  return (
    <div className="fixed left-5 bottom-5 z-50 flex hidden flex-col md:block">
      <div className="flex hidden flex-col">
        <audio
          ref={audio}
          autoPlay={isPlaying}
          loop={loop}
          onPlaying={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onEnded={handleEnded}
          src={playlist[index].src}
        />
        <div>🎶 {playlist[index].title}</div>
        <div className="text-sm">{playlist[index].artist}</div>
      </div>
      <div className="flex flex-row items-center rounded-[10px] bg-[rgba(255,255,255,0.5)] backdrop-blur-lg">
        <button className={classes.btn} onClick={handlePrev}>
          <PreviousIcon className={classes.nextPrev} />
        </button>
        {!isPlaying && (
          <button className={classes.btn} onClick={handlePlay}>
            <PlayIcon className={classes.play} />️
          </button>
        )}
        {isPlaying && (
          <button className={classes.btn} onClick={handlePause}>
            <PauseIcon className={classes.pause} />️
          </button>
        )}
        <button className={classes.btn} onClick={handleNext}>
          <ForwardIcon className={classes.nextPrev} />
        </button>
      </div>
    </div>
  )
}

export default MusicPlayer
