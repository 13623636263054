import React, { useMemo } from "react"
import { RecoilRoot } from "recoil"
import { RecoilURLSync } from "recoil-sync"
import MusicPlayer from "./src/components/music-player"
import RiddleSong from "./src/videos/casey-ceremony/casey-ceremony.mp3"

require("./src/css/index.css")

const HIDE_PLAYER_ROUTES = ["/bone-dragon", "/101ogdrop"]
const LOOP_PLAYER_ROUTES = ["/riddle"]

export const wrapPageElement = ({ element, props }) => {
  const hidePlayer = HIDE_PLAYER_ROUTES.includes(props.uri)
  const loopPlayer = LOOP_PLAYER_ROUTES.includes(props.uri)
  const playlistOverride = useMemo(() => {
    if(props.uri === '/riddle'){
      return [
        {
          title: "riddle song",
          artist: "artist",
          src: RiddleSong,
          autoplay: false,
        }
      ]
    }
  }, [props.uri])

  return (
    <RecoilRoot {...props}>
      <RecoilURLSync
        serialize={data => {
          if (data instanceof Map) {
            const serializedMap = {}
            ;[...data].forEach(([name, values]) => {
              if (values instanceof Set) {
                serializedMap[name] = [...values]
              } else {
                throw new Error(
                  "Map values other then Set currently not supported"
                )
              }
            })

            return `map:${JSON.stringify(serializedMap)}`
          }

          if (data === null) {
            return ""
          }

          return `${data}`
        }}
        deserialize={str => {
          if (str.startsWith("map:")) {
            const originalData = new Map()
            const serializedMap = str.substring(4)
            try {
              const deserializedMap = JSON.parse(serializedMap)

              for (const name in deserializedMap) {
                originalData.set(name, new Set(deserializedMap[name]))
              }
            } catch {
              // the error can be ignored
            }

            return originalData
          }

          return str
        }}
        location={{ part: "queryParams" }}
      >
        {element}
        {!hidePlayer && <MusicPlayer playlistOverride={playlistOverride} loop={loopPlayer}/>}
      </RecoilURLSync>
    </RecoilRoot>
  )
}
